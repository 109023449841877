import { Stage } from '@interfaces/Stage';
import logger from '@logger/logger';
import httpConfig from '@lib/apiClientFetch';

export const getStagesReq = async (): Promise<Stage[] | undefined> => {
  try {
    const response = await httpConfig.fetchWithInterceptor.get(`/api/stages`);

    const stagesRes: Stage[] = response.data;
    logger.debug('stagesRes:', stagesRes);

    return stagesRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetGameDetailReq:" + error);
    return undefined;
  }
};
