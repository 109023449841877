import logger from '@logger/logger';
import httpConfig from '@lib/apiClientFetch';
import { WinnerPrize, WinnerPrizeDetail } from '@interfaces/Winners';



export const getWinnerPrizesReq = async (): Promise<WinnerPrize[] | undefined> => {
  try {
    const response = await httpConfig.fetch.get(`/api/winners`);
    
    const winnerPrizeRes = response.data;
    logger.debug('winnerPrizeRes:', winnerPrizeRes);

    return winnerPrizeRes;
  } catch (error) {
    logger.error(error);
    // throw new Error("Failed on GetGameDetailReq:" + error);
    return undefined;
  }
}; 


interface getWinnerPrizesDetailProps {
    idPrize:number;
    type:number;
}


export const getWinnerPrizesDetailReq = async (params:getWinnerPrizesDetailProps): Promise<WinnerPrizeDetail[] | undefined> => {
    try {
      const response = await httpConfig.fetch.get(`/api/winners/detail`,{
        params
      });
      
      const winnerPrizeRes = response.data;
      logger.debug('winnerPrizeRes:', winnerPrizeRes);
  
      return winnerPrizeRes;
    } catch (error) {
      logger.error(error);
      // throw new Error("Failed on GetGameDetailReq:" + error);
      return undefined;
    }
  }; 