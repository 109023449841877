import { useEffect, useState } from 'react';
import {
  Text,
  Image,
  Box,
  Flex,
  Container,
  SimpleGrid,
  Center,
  Hide,
  VStack,
  Heading,
  Button,
  Show,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { GameStatus } from '@interfaces/Game';
import { useToast } from '@chakra-ui/react';
import { useSession } from 'next-auth/react';
import logger from '@logger/logger';
import Games from './components/Games';
import { MdChevronRight, MdLockOutline, MdLogin } from 'react-icons/md';
import { useStages } from '@hooks/useStages';
import Statistics from './components/Statistics';
import WinnerPrizes from '@components/WinnerPrizes';

export default HomeModule;

function HomeModule() {
  const toast = useToast();
  const router = useRouter();
  const { stages, loadNewStages, getQuizStage } = useStages();
  const [loading, setLoading] = useState<boolean>(true);
  const { data: session, status } = useSession();

  const localStorageKey = `07511df3-dbbf_get_stages`;

  function convertDateToUTC(date: Date) {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }

  const handlerMinutesGetStages = () => {
    if (typeof window === 'undefined') return;
    let logicPrizesStr = window.localStorage.getItem(localStorageKey);
    let sendRequest = false;
    let today = new Date();
    let nowTimestamp = convertDateToUTC(today).valueOf();
    if (logicPrizesStr != null) {
      let logicPrizes = JSON.parse(logicPrizesStr);
      if (logicPrizes.dt_timestamp_expires < nowTimestamp) {
        sendRequest = true;
      }
    }

    if (sendRequest || logicPrizesStr == null) {
      loadStages();
      const tomorrowMidnight = new Date(today);
      tomorrowMidnight.setDate(tomorrowMidnight.getDate() + 1);
      tomorrowMidnight.setHours(0);
      tomorrowMidnight.setMinutes(1);
      window.localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          dt_timestamp_expires: convertDateToUTC(tomorrowMidnight).valueOf(),
        })
      );
    }
  };

  function loadStages() {
    logger.info('loadStates called');
    loadNewStages().then(() => {
      setLoading(false);
    });
  }
  useEffect(() => {
    const idInterval = setInterval(() => {
      handlerMinutesGetStages();
    }, 1 * 60 * 1000);
    return () => {
      clearInterval(idInterval);
    };
  }, []);

  useEffect(() => {
    if (status !== 'loading' && !stages) {
      setLoading(true);
      loadStages();
    }
  }, [status, stages]);

  function renderActionButton() {
    const stage = getQuizStage();
    if (!stage || !stage?.games[0]) {
      return (
        <Button
          rightIcon={<MdLockOutline />}
          colorScheme="orange"
          isLoading={loading}
          loadingText="Carregando"
          spinnerPlacement="start"
          onClick={() =>
            toast({
              title: 'Jogo bloqueado.',
              description:
                'Não é possível jogar, aguarde novidades para jogar novamente!',
              status: 'warning',
              duration: 9000,
              isClosable: true,
            })
          }
        >
          Bloqueado
        </Button>
      );
    }
    // game.
    // let game = stage?.games[0];
    switch (Number(stage?.games[0].status)) {
      case GameStatus.Unlocked:
        return (
          <Button
            rightIcon={<MdChevronRight color="#14284B" fontSize={'18px'} />}
            bgColor="#FFBB38"
            fontFamily={'Inter'}
            fontWeight={500}
            textAlign="center"
            justifyContent="center"
            onClick={() => {
              router.push(
                `/game?id_game=${stage?.games[0].idGame}&id_stage=${stage?.games[0].idStage}&type=${stage?.games[0].type}`
              );
              // toast({
              //   title: 'Promoção Encerrada.',
              //   description:
              //     'Não é possível jogar, aguarde novidades para jogar novamente!',
              //   status: 'warning',
              //   duration: 9000,
              //   isClosable: true,
              // })
            }}
          >
            Jogar agora!
          </Button>
        );
      case GameStatus.Finished:
        return (
          <Button
            rightIcon={<MdLockOutline />}
            colorScheme="orange"
            isLoading={loading}
            loadingText="Carregando"
            spinnerPlacement="start"
            onClick={() =>
              toast({
                title: 'Jogo bloqueado.',
                description:
                  'Não é possível jogar, aguarde novidades para jogar novamente!',
                status: 'warning',
                duration: 9000,
                isClosable: true,
              })
            }
          >
            Jogo expirado nessa fase
          </Button>
        );
      case GameStatus.Played:
        return (
          <>
            <Button
              rightIcon={<MdLockOutline />}
              bgColor="#F14971"
              color="white"
              fontWeight={400}
              isLoading={loading}
              loadingText="Carregando"
              spinnerPlacement="start"
              onClick={() =>
                toast({
                  title: 'Aguarde até amanhã!',
                  description:
                    'Volte amanhã para jogar novamente. Ou acesse seu perfil para mais informações.',
                  status: 'warning',
                  duration: 9000,
                  isClosable: true,
                })
              }
            >
              Você já jogou hoje
            </Button>
            <Text
              color="white"
              fontSize={['md', 'md', 'md', 'lg']}
              align="center"
              px={[0, 10, 10]}
            >
              Volte amanhã para jogar mais!
            </Text>
          </>
        );
      case GameStatus.Locked:
      default:
        if (!session) {
          return (
            <Button
              leftIcon={<MdLogin />}
              colorScheme="orange"
              isLoading={loading}
              loadingText="Carregando"
              spinnerPlacement="start"
              onClick={() => {
                router.push(
                  `/game?id_game=${stage?.games[0].idGame}&id_stage=${stage?.games[0].idStage}&type=${stage?.games[0].type}`
                );
                // toast({
                //   title: 'Promoção Encerrada.',
                //   description:
                //     'Não é possível jogar, aguarde novidades para jogar novamente!',
                //   status: 'warning',
                //   duration: 9000,
                //   isClosable: true,
                // });
              }}
            >
              Jogar agora!
            </Button>
          );
        } else {
          return (
            <Button
              rightIcon={<MdLockOutline />}
              colorScheme="orange"
              isLoading={loading}
              loadingText="Carregando"
              spinnerPlacement="start"
              onClick={() =>
                toast({
                  title: 'Jogo bloqueado.',
                  description:
                    'Não é possível jogar, aguarde novidades para jogar novamente!',
                  status: 'warning',
                  duration: 9000,
                  isClosable: true,
                })
              }
            >
              Bloqueado
            </Button>
          );
        }
    }
  }

  const renderInfos = () => {
    if (loading) {
      return <Statistics />;
    }
    if (!loading && status == 'authenticated') {
      return <Statistics />;
    }
    return (
      <VStack
        spacing={[5, 10, 10, 20]}
        direction={['row', 'row']}
        textAlign="center"
      >
        <Heading color="white" fontSize={{ base: '24px', md: '32px' }} as="em">
          É fácil participar do bolão!
        </Heading>
        <Text
          color="white"
          fontSize={{ base: '14px', md: '18px', lg: '22px' }}
          opacity={0.8}
        >
          Depois de fazer o cadastro, é só clicar no jogo que quer palpitar e
          digitar o placar. <br />
          Você decide se quer dar todos os palpites de uma vez ou jogo a jogo!
          Importante é que o palpite deve ser dado ou trocado até 1 hora antes
          de cada partida. <br />
          <strong>Não se esqueça!</strong>
        </Text>
        <Text
          color="#FFD200"
          fontSize={{ base: '14px', md: '18px', lg: '22px' }}
        >
          Para dar palpites, é necessário se cadastrar antes e estar logado!
        </Text>
      </VStack>
    );
  };
  const renderHeaderDetails = () => {
    const Title = () => (
      <Heading
        color="white"
        fontSize={{ base: '20px', md: '30px', lg: '38px' }}
        as="em"
      >
        Participe da promoção!
        <br />É <u>GRÁTIS</u> e você pode ganhar <u>CENTENAS</u> de prêmios!
      </Heading>
    );
    if (loading) {
      return (
        <>
          <Title />
        </>
      );
    }
    if (!loading && status == 'authenticated') {
      return (
        <>
          <Title />
        </>
      );
    }
    return (
      <>
        <Hide below="sm">
          <Title />
          <Text
            color="white"
            fontSize={{ base: '14px', md: '18px', lg: '22px' }}
            opacity={0.8}
          >
            <strong>CONCORRA GRÁTIS</strong> a diversos prêmios até o fim de
            dezembro!
            <br />É só se cadastrar e palpitar no
            <strong> Bolão {process.env.NEXT_PUBLIC_APP_NAME}</strong>. E você
            ainda pode responder a uma pergunta por dia no <strong>Quiz</strong>{' '}
            para concorrer a prêmios instantâneos todos os dias*!
          </Text>
        </Hide>
        <Show below="sm">
          <Text
            color="white"
            fontSize={{ base: '22px', md: '32px', lg: '44px' }}
          >
            <strong>CONCORRA GRÁTIS</strong> a diversos prêmios até o fim de
            dezembro!
            <br />
          </Text>
          <Heading
            color="white"
            fontSize={{ base: '20px', md: '18px', lg: '22px' }}
            opacity={0.8}
          >
            É só se cadastrar e palpitar no{' '}
            <strong>Bolão {process.env.NEXT_PUBLIC_APP_NAME}</strong>. <br />E
            você ainda pode responder a uma pergunta por dia no{' '}
            <strong>Quiz</strong> para concorrer a prêmios instantâneos todos os
            dias*!
          </Heading>
        </Show>
        <Box
          as={motion.div}
          dragConstraints={{ left: -100, right: 100 }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition="0.05s linear"
        >
          <Center flexDir={'column'}>
            <Button
              borderWidth="3px"
              p={8}
              pl={10}
              pr={10}
              borderColor="#AF0FA9"
              _hover={{ bg: 'orange.500' }}
              _active={{ bg: 'orange.500' }}
              borderRadius={30}
              variant="outline"
              isLoading={loading}
              loadingText="Carregando"
              spinnerPlacement="start"
              size="lg"
            >
              <Text color="white">
                PROMOÇÃO
                <br />
                ENCERRADA!
              </Text>
            </Button>
          </Center>
        </Box>
      </>
    );
  };
  const renderHeaderBanner = () => {
    if (loading) {
      return (
        <Box width={{ md: '20%' }} mr={[0, 0, 5]} mb={5}>
          <Image src={'/imgs/banner.png'} />
        </Box>
      );
    }
    if (!loading && status == 'authenticated') {
      return (
        <Box width={{ md: '20%' }} mr={[0, 0, 5]} mb={5}>
          <Image src={'/imgs/banner.png'} />
        </Box>
      );
    }
    return (
      <Box width={{ md: '90%' }} mr={[0, 0, 5]} mb={5}>
        <Image src={'/imgs/banner.png'} />
      </Box>
    );
  };

  // if(loading){
  //   return <Loading loading={loading} />
  // }

  return (
    <>
      <Box color="white">
        <Flex w="full" h="full">
          {/* {loading ? (
            <Center my={[10, 10, 20]} minH="440px">
              <Spinner size="xl" speed="0.65s" alignSelf={'center'} />
            </Center>
          ) : ( */}
          <Container
            maxW={{ base: 'full', md: '9xl', lg: '8xl' }}
            // p={[5, 5, 5, 5]}
            py={[2, 2, 2, 2]}
            textAlign="center"
          >
            <Flex flexDir={['column', 'column', 'row']}>
              {renderHeaderBanner()}
              <VStack
                spacing={[0, 5, 5, 5]}
                direction={['row', 'row']}
                justify="center"
                w="full"
              >
                {renderHeaderDetails()}
              </VStack>
            </Flex>
            {/* </SimpleGrid> */}
          </Container>
          {/* )} */}
        </Flex>
      </Box>
      <Box
        id="game-banner"
        background="linear-gradient(195.04deg, #005AFF 11.4%, #1F1960 84.99%);"
      >
        <Center color="white" flexDir="column" textAlign="center">
          <Heading
            color="white"
            fontSize={{ base: '24px', md: '32px', lg: '32px' }}
            pt={5}
          >
            Bolão {process.env.NEXT_PUBLIC_APP_NAME}
          </Heading>
          {!loading && status == 'authenticated' && (
            <Text color="#FFD200">
              Clique no jogo para dar o seu palpite. Você pode fazer isso até 1
              hora antes do jogo começar.
            </Text>
          )}
        </Center>
        <Flex w="full" h="full">
          <Container
            maxW={{ base: 'full', md: '9xl', lg: '8xl' }}
            p={[5, 5, 5, 5]}
            py={[5, 5, 10, 10]}
          >
            <SimpleGrid
              minChildWidth="250px"
              columns={2}
              spacing={[5, 5, 5, 20]}
              alignItems="center"
            >
              <Box>{renderInfos()}</Box>
              <Box
                border="3px solid"
                borderColor="white"
                borderRadius={10}
                h={['auto', 'auto', '-webkit-fill-available']}
              >
                <Games />
              </Box>
            </SimpleGrid>
          </Container>
        </Flex>
      </Box>
      <Box py="5">{/*do not remove */}</Box>
      <Box
        bgColor="#FFD200"
        as={motion.div}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition="1s linear"
        viewport={{ once: true }}
      >
        <Center>
          <Container
            backgroundColor="#005AFF"
            maxW={{ base: 'full', md: '9xl', lg: '8xl' }}
            m={10}
            borderRadius={10}
            p={[5, 5, 5, 5]}
            py={[5, 5, 10, 10]}
            id="scrolling_quiz_game_div"
          >
            <Box textAlign="center">
              <VStack spacing={5}>
                <Image
                  src="/icons/game-quiz-bolao.svg"
                  height={20}
                  width={20}
                />
                <Text
                  color="white"
                  fontSize={{ base: '20px', md: '20px', lg: '3xl' }}
                  fontWeight={700}
                >
                  Quiz {process.env.NEXT_PUBLIC_APP_NAME}
                </Text>
                <Text fontSize="2xl" color="white" fontWeight={400}>
                  Uma pergunta por dia, fácil, fácil. <br />
                  Você se cadastra e responde todo dia pra aumentar suas <br />
                  chances de ganhar os prêmios!
                </Text>
                {renderActionButton()}
              </VStack>
            </Box>
          </Container>
        </Center>
      </Box>
      <Box py="5">{/*do not remove */}</Box>
      <Box
        background="linear-gradient(180deg, #BFD0F0 0%, #0355F2 99.37%);"
        py={5}
      >
        <Show above="md">
          <Show above="lg">
            <Image src="/imgs/prizes.png" width="100%" />
          </Show>
          <Hide above="lg">
            <Image src="/imgs/prizes-tablets.png" width="100%" />
          </Hide>
        </Show>
        <Show below="md">
          <Image src="/imgs/prizes-smartphones.png" width="100%" />
        </Show>
      </Box>
      <WinnerPrizes />
    </>
  );
}
